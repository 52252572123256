import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

type PledgeState = {
  amount?: string
  pledgeType?: string
  varSymbol?: string
}

function PledgePage() {
  const location = useLocation()
  const state: PledgeState = location.state || {}
  const { amount, pledgeType, varSymbol } = state
  const isMonthlyPledge = pledgeType === 'monthly'
  const accountNumber = '2801844579/2010'

  if (!amount || !varSymbol) {
    return <Redirect to="/" />
  }

  const qrCodeUrl = `https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=2801844579&bankCode=2010&amount=${amount}&currency=CZK&message=Dar&vs=${varSymbol}`

  return (
    <div className="jumbotron my-4">
      <h1 className="display-4">Děkujeme za Vaši podporu!</h1>
      <h3 className='py-4'>I díky Vašemu daru můžeme bojovat za důstojnější, bezpečnější a spravedlivější budoucnost pro všechny.</h3>
      <hr className='my-4' />

      <div className="larger">
        {isMonthlyPledge && (
          <>
            <p>Teď už zbývá to nejpodstatnější - nastavení trvalého příkazu. Pravidelný dar ve výši <b>{amount}&nbsp;Kč</b> posílejte na účet:&nbsp;<b>{accountNumber}</b> a jako variabilní symbol uveďte:&nbsp;<b>{varSymbol}</b>. Do <b>zprávy pro příjemce</b> prosím uveďte "<b>Dar</b>". Indikaci daru po nás požaduje zákon.</p>
            <p>Pro platbu můžete využít i následujícího QR kódu, který Vám údaje pro platbu ve Vaší mobilní aplikaci automaticky vyplní. Při jeho použití nezapomeňte ale přenastavit platbu z jednorázové na trvalou.</p>
          </>
        )}

        {!isMonthlyPledge && (
          <>
            <p>Teď už zbývá to nejpodstatnější - nastavení platby. Váš dar ve výši <b>{amount}&nbsp;Kč</b> pošlete na účet:&nbsp;<b>{accountNumber}</b> a jako variabilní symbol uveďte:&nbsp;<b>{varSymbol}</b>. Do <b>zprávy pro příjemce</b> prosím uveďte "<b>Dar</b>". Indikaci daru po nás požaduje zákon.</p>
            <p>Pro platbu můžete využít i následujícího QR kódu, který Vám údaje pro platbu ve Vaší mobilní aplikaci automaticky vyplní. Budeme zároveň vděční, pokud zvážíte Budoucnost podpořit pravidelným darem.</p>
          </>
        )}

        <p>Děkujeme!</p>
      </div>

      <div className='text-center'>
        <img src={qrCodeUrl} alt="QR platba" />
      </div>
    </div>
  )
}

export default PledgePage
