import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import CampaignPage from './CampaignPage'
import PledgePage from './PledgePage'

function App() {
  return (
    <Router>
      <Route exact path="/">
        <CampaignPage />
      </Route>
      <Route path="/podekovani">
        <PledgePage />
      </Route>
    </Router>
  )
}

export default App
