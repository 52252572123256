import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap-formik'
import { Formik, FormikValues, FormikProps } from 'formik'
import * as Yup from 'yup'
import subYears from 'date-fns/fp/subYears'
import parse from 'date-fns/fp/parse'
import ky from 'ky'
import { customAlphabet } from 'nanoid'
import ButtonGroupField from './ButtonGroupField'

const generateVarSymbol = customAlphabet('1234567890', 10)
const parseBirthDate = parse(new Date())('dd.MM.yyyy')

Yup.setLocale({
  mixed: {
    required: ({ label }) => `${label} je povinné pole.`
  },
  string: {
    email: 'Uveďte prosím platnou e-mailovou adresu.'
  }
})

const PledgeSchema = Yup.object().shape({
  amount: Yup.number()
    .required()
    .moreThan(0, 'Částka by měla být vyšší než 0. 💸'),
  firstName: Yup.string().required().label('Jméno'),
  lastName: Yup.string().required().label('Příjmení'),
  dateOfBirth: Yup.date().when('legal', {
    is: 'person',
    then: Yup.date()
      .required()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value
        return parseBirthDate(originalValue.replace(/\s/g, ''))
      })
      .max(subYears(15, Date.now()), 'Dárce musí být starší 15 let.')
      .label('Datum narození')
  }),
  email: Yup.string().email().required().label('E-mail'),
  companyName: Yup.string()
    .when('legal', {
      is: 'company',
      then: Yup.string().required()
    })
    .label('Název firmy'),
  companyRegNumber: Yup.string()
    .when('legal', {
      is: 'company',
      then: Yup.string().required()
    })
    .label('IČO'),
  street: Yup.string().label('Ulice'),
  city: Yup.string().required().label('Město'),
  zipNumber: Yup.string()
    .matches(/\d{3} ?\d{2}/, 'Hodnota není ve tvaru PSČ.')
    .label('PSČ')
})

const initialValues = {
  legal: 'person',
  pledgeType: 'onetime',
  amount: 500,
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  companyName: '',
  companyRegNumber: '',
  street: '',
  city: '',
  zipNumber: '',
  phoneNumber: ''
}

type PledgeFormProps = {
  campaign: string
}

function PledgeForm({ campaign = '' }: PledgeFormProps) {
  const history = useHistory()

  async function handleSubmit(values: FormikValues) {
    const varSymbol = generateVarSymbol()

    const urlParams = new URLSearchParams(
      `form-name=dar&campaign=${campaign}&varSymbol=${varSymbol}`
    )

    for (const [key, value] of Object.entries(values)) {
      urlParams.set(key, value)
    }

    try {
      await ky.post('/', {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: urlParams.toString()
      })
      console.log(urlParams.toString())

      history.push('/podekovani', {
        amount: values.amount,
        pledgeType: values.pledgeType,
        varSymbol
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Formik
      validationSchema={PledgeSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<FormikValues>) => {
        const isCompany = props.values.legal === 'company'

        return (
          <form onSubmit={props.handleSubmit} noValidate>
            <ButtonGroupField
              name="legal"
              options={[
                { key: 'person', label: 'Fyzická osoba' },
                { key: 'company', label: 'Právnická osoba' }
              ]}
              label="Jsem"
            />

            <ButtonGroupField
              name="pledgeType"
              options={[
                { key: 'onetime', label: 'Jednorázově' },
                { key: 'monthly', label: 'Měsíčně' }
              ]}
              label="Daruji"
            />

            <ButtonGroupField
              name="amount"
              options={[
                { key: '500', label: '500 Kč' },
                { key: '1000', label: '1000 Kč' },
                { key: '2000', label: '2000 Kč' },
                { key: '3000', label: '3000 Kč' }
              ]}
              customValue={{
                label: 'Vlastní částku',
                prepend: '',
                append: 'Kč'
              }}
              label="Částku"
            />

            <div className="form-row">
              <div className="col-md">
                <Form.Input name="firstName" label="Jméno*" />
              </div>
              <div className="col-md">
                <Form.Input name="lastName" label="Příjmení*" />
              </div>
              <div className="col-md">
                <Form.Input name="email" label="E-mail*" type="email" />
              </div>
            </div>
            {!isCompany && (
              <Form.Input
                name="dateOfBirth"
                label="Datum narození*"
                placeholder="dd.mm.yyyy"
              />
            )}

            {isCompany && (
              <>
                <Form.Input name="companyName" label="Název firmy*" />
                <Form.Input name="companyRegNumber" label="IČO*" />
              </>
            )}
            <Form.Input name="city" label="Město/Obec*" />
            <Form.Input name="street" label="Ulice a č.p." />
            <Form.Input name="zipNumber" label="PSČ" />
            <Form.Input name="phoneNumber" label="Telefonní číslo" />

            <Button type="submit" disabled={props.isSubmitting}>
              Darovat
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}

export default PledgeForm
