import React from 'react'
import { useTitle } from 'react-use'

import PledgeForm from './PledgeForm'

function CampaignPage() {
  useTitle('Darovací formulář hnutí Budoucnost')

  return (
    <>
      <h1 className="pt-4 pb-2">
        Podpořte Budoucnost
      </h1>

      <p>
        Děkujeme Vám, že jste se rozhodli finančně podpořit zásadovou a
        spravedlivou politiku. Politiku, která věří, že lidé mají právo na
        život, který jim dává smysl. Proto je potřeba zajistit dostupnou
        zdravotní péči, konec nespravedlivých exekucí, solidní bydlení za
        dostupné ceny nebo zdravou krajinu i pro naše děti.
      </p>

      <hr />

      <div className="mt-4">
        <PledgeForm campaign="budoucnost" />
      </div>

      <div className="my-4">
        <p>
          Zpracovatelem darů hnutí Budoucnost. Pokud máte jakékoliv dotazy, ozvěte se nám
          prosím na e-mail{' '}
          <a href="mailto:dary@volim-budoucnost.cz">dary@volim-budoucnost.cz</a>
          .
        </p>
        <p>
          Vyplněním tohoto formuláře poskytujete údaje do darovací smlouvy s
          hnutím Budoucnost (IČ 09182004). Smlouvu vám zašleme na uvedený
          e-mail, začátkem příštího roku Vám také vystavíme potvrzení o přijetí
          daru, které můžete použít pro odečet v daňovém přiznání.
        </p>
        <p>
          Budoucnost zpracovává osobní údaje dárců na základě zákonné povinnosti
          evidovat dárce politických stran v souladu se zákonem č. 424/1991 Sb.
          Vezměte, prosím, na vědomí, že osobní údaje v rozsahu jméno, příjmení,
          datum narození a obec trvalého bydliště, budou zveřejněny na webových
          stránkách Budoucnosti a Úřadu pro dohled nad hospodařením politických
          stran a hnutí jako součást výroční zprávy nebo zprávy o volební
          kampani. E-mailovou adresu a číslo telefonu zpracovává Budoucnost na
          základě svého oprávněného zájmu za účelem přímého marketingu a rozvoje
          vztahu s dárci. Proti takovému zpracování je možné vznést námitku na{' '}
          <a href="mailto:dary@volim-budoucnost.cz">dary@volim-budoucnost.cz</a>
          .
        </p>
      </div>
    </>
  )
}

export default CampaignPage
