import React, { useState } from 'react'
import {
  Button,
  ButtonToolbar,
  ButtonGroup,
  InputGroup,
  Form
} from 'react-bootstrap'
import { useField } from 'formik'

function ButtonGroupField({
  options,
  label = '',
  customValue = {},
  ...props
}: any) {
  const [isCustomSelected, setCustomSelected] = useState(false)
  const [field, , helpers] = useField(props)
  const { value } = field
  const { setValue } = helpers

  function handlePremade(key: string) {
    setCustomSelected(false)
    setValue(key)
  }

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <ButtonToolbar>
        <ButtonGroup className="mr-2">
          {options.map(({ key, label }: any) => {
            const variant =
              key === String(value) && !isCustomSelected ? 'primary' : 'light'

            return (
              <Button
                key={key}
                variant={variant}
                onClick={() => handlePremade(key)}
              >
                {label}
              </Button>
            )
          })}
          {customValue.label && (
            <Button
              variant={isCustomSelected ? 'primary' : 'light'}
              onClick={() => setCustomSelected(true)}
            >
              {customValue.label}
            </Button>
          )}
        </ButtonGroup>

        {isCustomSelected && (
          <InputGroup>
            <Form.Control type="text" {...field} />
            {customValue.prepend && (
              <InputGroup.Prepend>
                <InputGroup.Text id="btnGroupAddon">
                  {customValue.prepend}
                </InputGroup.Text>
              </InputGroup.Prepend>
            )}
            {customValue.append && (
              <InputGroup.Append>
                <InputGroup.Text id="btnGroupAddon">
                  {customValue.append}
                </InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
        )}
      </ButtonToolbar>
    </Form.Group>
  )
}

export default ButtonGroupField
